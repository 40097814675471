$sidebar-width: 280px;

/* You can add global styles to this file, and also import other style files */
body {
    margin: 0px;

    background: rgb(239,243,248);
    color: #121212;

    min-height: 100vh;
    overflow-y: scroll;
    
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    margin: 0rem;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.2;
    color: inherit;
}

h1, .h1 {
    font-size: 3rem;
    line-height: 3.625rem;
    letter-spacing: -0.015em;
}

h2, .h2 {
    font-size: 2.25rem;
    line-height: 2.75rem;
    letter-spacing: -0.015em;
}

h3, .h3 {
    font-size: 1.5rem;
    line-height: 2rem;
}

h4, .h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

h5, .h5 {
    font-size: 1rem;
    line-height: 1.5rem;
}

h6, .h6 {
    font-size: .875rem;
    line-height: 1.25rem;
}

p,.p {
    margin-top: 1rem;
}

.text-secondary {
    color: #596080 !important;
}

.text-bare {
    color: #bababa !important;
}

.text-small {
    font-size: .875rem;
    line-height: 1.5rem;
}

.text-smaller {
    font-size: .75rem;
    line-height: 1.5rem;
}

.text-undecorated {
    text-decoration: none;
}

.text-align {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

.layout-content {
    padding: 2rem;
    box-sizing: border-box;

    margin-left: $sidebar-width; 
}

.layout-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    width: $sidebar-width;

    padding: 2rem;
    box-sizing: border-box;

    background: white;
    box-shadow: 0px 4px 50px #D9DDFC;;
}

.fancy-card {
    background: white;
    border-radius: 4px;
    box-shadow: 0px 4px 50px #d9ddfc;
    height: 100%;

    &-content {
        padding: 2rem;
        box-sizing: border-box;
    }
}

hr {
    display: block;
    
    border: none;
    height: 1px;
    background-color: #d4d4d8;
}

.required {
    color: red;
}

.text-muted {
    color: #bababa;
}

.text-small {
    font-size: 0.75em;
    line-height: 1.5rem;
}

$success-regular:#4F46E5;
$success-color:#22C55E;
$success-warning:#F59E0B;
$success-danger:#EF4444;

.p-tag.p-tag-outline {
    &.p-tag {
        background-color: #fff;
        border: 1px solid $success-regular;
        color: $success-regular;
    }

    &.p-tag-success {
        background-color: #fff;
        border: 1px solid $success-color;
        color: $success-color;
    }

    &.p-tag-danger {
        background-color: #fff;
        border: 1px solid $success-danger;
        color: $success-danger;
    }

    &.p-tag-warning {
        background-color: #fff;
        border: 1px solid $success-warning;
        color: $success-warning;
    }
}

i {
    &.success {
        color: $success-color;
    }
    
    &.danger {
        color: $success-danger;
    }

    &.warning {
        color: $success-warning;
    }
}

p-dataView {
    &.transparent {
        & .p-dataview-content {
            background-color: transparent !important;
        }
    }
}

